<template>
    <div class="section-2">
        <div class="container">
            <div class="row p-0 m-0">
                <div class="col-12 col-lg-12">
                    <div class="">
                        <div class="fl-x-cl mt-4">
                            <img class="logo" src="../../assets/web/login/logoo.png">
                        </div>
                    </div>
                    <div class="dg-sm-mt" align="center">
                        <p class="fs-3 mb-2 mg margin-1">Welcome to KLDB Training Programs</p>
                        <p class="font-lato-bold fs-lg-8 mb-0">Training Registration</p>
                        <p class="font-lato-light fs--1 pb-4">You have to enter the details below to register a training
                            program</p>
                        <div class="col-lg-6">
                            <div class="row">
<!--                                <div class="col-lg-12">-->
<!--                                    <validated-input size="sm" type="radio" label="Are you Employed" placeholder="Name"-->
<!--                                                     border-radius="0" name="name"-->
<!--                                                     id="employment"-->
<!--                                                     class="place-holder-white c-input bdr-radius">-->
<!--                                    </validated-input>-->
<!--                                </div>-->
                                <div class="col-lg-6">
                                    <validated-input size="sm" placeholder="Organization Name"
                                                     name="name"
                                                     id="name"
                                                     class="place-holder-white c-input bdr-radius">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input size="sm" placeholder="Previous Position Held"
                                                     name="createpassword" id="prev-position"
                                                     class="place-holder-white c-input">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input size="sm" placeholder="Type of Organization"
                                                     name="type-of-organization"
                                                     id="type-of-organization"
                                                     class="place-holder-white c-input">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <h2 class="text-white mb-3 mt-lg-2 fs-lg-2 font-lato-semi-bold">Education Qualification</h2>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input size="sm" placeholder="Present Position"
                                                     name="type-of-organization"
                                                     id="present-position"
                                                     class="place-holder-white c-input">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-select size="sm" placeholder="Educational Qualification"
                                                      name="type-of-organization"
                                                      id="edu-qualification"
                                                      class="place-holder-white c-input-select-b-white-1 c-input sm-input">
                                    </validated-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input size="sm" placeholder="Since"
                                                     name="since"
                                                     id="since"
                                                     class="place-holder-white c-input">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <h2 class="text-white mt-lg-2 mb-3 mb-lg-0 fs-lg-2 font-lato-semi-bold mb-0">Language Proficiency</h2>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input size="sm" placeholder="Year of Service" border-radius="0"
                                                     name="since"
                                                     id="year-of-service"
                                                     class=" place-holder-white c-input">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-select size="sm" placeholder="Native Language"
                                                      name="native-langulage"
                                                      id="native-langulage"
                                                      class="place-holder-white c-input-select-b-white-1 c-input sm-input">
                                    </validated-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input size="sm" placeholder="From - To (Year)"
                                                     name="native-langulage"
                                                     id="from-to-year"
                                                     class="place-holder-white c-input">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-select size="sm" placeholder="English Proficiency"
                                                      name="english-proficiency"
                                                      id="english-proficiency"
                                                      class="place-holder-white c-input-select-b-white-1 c-input sm-input">
                                    </validated-select>
                                </div>
                            </div>
                            <div class="btn-group">
                                <btn border-radius="0" design="basic-b" class="px-5 py-2 mb-4 bdr-radius" text="Back"></btn>
                                <btn border-radius="0" class="px-5 py-2 mb-4 bdr-radius" text="Submit"></btn>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentNotLoggedIn'
};
</script>

<style scoped>
.section-2 {
    background-image: url("../../assets/web/register/bgi.png");
    background-position: center 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.container {
    color: white;
}

.i-icon {
    position: absolute;
    top: 56px;
    right: -8px;
}

.logo {
    height: 78px;
    width: 148px;
}

.fb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.gugl {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.mg {
    margin-top: -48px;
}

.icon-set {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.place-holder-white {

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
}

}
.bdr-radius {
    border-radius: 1px;
}

.icon-white {

svg {
    color: white !important;
}

}

/*..mx-icon-calendar*/
.mx-icon-calendar, .mx-icon-clear {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 1;
    color: white !important;
    vertical-align: middle;
}

@media (max-width: 750px) {
    .margin-1 {
        margin-top: 40px;
    }
}

</style>
